
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'
import { pInteger } from '@/utils/validate'
@Component({
  name: 'AddBuild',
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})
export default class AddBuild extends Vue {
  private info = {
    housingName: '',
    buildingName: '',
    buildingFloor: '',
    buildingHousehold: '',
    notes: '',
    fileList: []
  }

  private oldInfo = {
    housingName: '',
    buildingName: '',
    buildingFloor: '',
    buildingHousehold: '',
    notes: '',
    fileList: []
  }

  private rules ={
    housingName: [{ required: true, message: '请输入小区名称', trigger: ['change'] }],
    buildingName: [{ required: true, message: '请输入楼栋名称', trigger: ['change'] }],
    buildingFloor: [{ required: true, message: '输入楼栋数量', trigger: ['change'] },
      { validator: pInteger, trigger: ['blur', 'change'] }],
    buildingHousehold: [{ required: true, message: '输入每层户数', trigger: ['change'] },
      { validator: pInteger, trigger: ['blur', 'change'] }]
  }

  private submitShow = false

  get buildingId () {
    return this.$route.query.buildingId
  }

  get userName () {
    let name = ''
    if (this.$store.state.userInfo && this.$store.state.userInfo.userName) {
      name = this.$store.state.userInfo.userName
    } else if (window.sessionStorage.getItem('userInfo')) {
      name = JSON.parse(window.sessionStorage.getItem('userInfo') as any).userName
    }
    return name
  }

  created () {
    if (this.buildingId) {
      this.getBuildDetail()
    } else {
      this.info.housingName = this.userName
    }
  }

  getBuildDetail () {
    this.$axios.get(this.$apis.smartHouse.selectBuildingByBuildingId, { buildingId: this.buildingId }).then(res => {
      if (res) {
        this.info = res
        this.info.housingName = this.userName
        this.oldInfo = JSON.parse(JSON.stringify(res))
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    (this.info as any).fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        const params = JSON.parse(JSON.stringify(this.info))
        let url = ''
        if (this.buildingId) {
          // console.log('编辑', this.oldInfo, params.buildingFloor)
          if (this.oldInfo.buildingFloor > params.buildingFloor) {
            this.$message.warning('楼层层数只可增大不可减小')
            return
          } else if (this.oldInfo.buildingHousehold > params.buildingHousehold) {
            this.$message.warning('每层户数只可增大不可减小')
            return
          }
          url = this.$apis.smartHouse.updateBuilding
        } else {
          url = this.$apis.smartHouse.insertBuilding
        }
        this.submitShow = true
        delete params.housingName
        this.$axios.post(url, params).then(() => {
          // this.$message.success('保存成功')
          this.$router.push({ path: '/smartHouse/build' })
        }).catch(() => {
          this.submitShow = false
        })
      }
    })
  }
}
